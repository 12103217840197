import axios from "axios";

var suporte = null;
var erp = null;
var fbvest = null;
var gerencia = null;
var operacao = null;
var sistemas = null;
var internet = null;
var seguranca = null;

const Suporte = async () => {
  if (suporte == null) {
    const resposta = await axios.get("_equipe/suporte.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      suporte = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return suporte;
  }
};

const ERP = async () => {
  if (erp == null) {
    const resposta = await axios.get("_equipe/erp.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      erp = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return erp;
  }
};

const FBVest = async () => {
  if (fbvest == null) {
    const resposta = await axios.get("_equipe/fbvest.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      fbvest = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return fbvest;
  }
};

const Gerencia = async () => {
  if (gerencia == null) {
    const resposta = await axios.get("_equipe/gerencia.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      gerencia = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return gerencia;
  }
};

const Internet = async () => {
  if (internet == null) {
    const resposta = await axios.get("_equipe/internet.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      internet = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return internet;
  }
};

const Operacao = async () => {
  if (operacao == null) {
    const resposta = await axios.get("_equipe/operacao.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      operacao = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return operacao;
  }
};

const Sistemas = async () => {
  if (sistemas == null) {
    const resposta = await axios.get("_equipe/sistemas.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      sistemas = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return sistemas;
  }
};

const Seguranca = async () => {
  if (seguranca == null) {
    const resposta = await axios.get("_equipe/seguranca.json");
    if (resposta.status !== 200) {
      throw new Error(resposta.data.exception.errorMessage);
    } else {
      seguranca = resposta.data;
      return resposta.data.message ? resposta.data.message : resposta.data;
    }
  } else {
    return seguranca;
  }
};


export default { Suporte, ERP, FBVest, Gerencia, Internet, Operacao, Sistemas, Seguranca };
