import React, { Component } from 'react';

class Progress extends Component {
  state = { isLoaded: false, scrollPostion: 0 };

  componentDidMount() {
    this.setState({ isLoaded: true });
    this.listenToScrollEvent();
  }

  listenToScrollEvent = () => {
    document.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  };

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

    this.setState({
      scrollPostion,
    });
  };

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
  };

  render() {
    if (this.state.isLoaded === true) {
      var header = window.document.getElementById('myHeader');

      window.onscroll = function () {
        lockHeader();
      };
      function lockHeader() {
        if (window.pageYOffset >= 251) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    }
    return (
      <div className="ontop" id="myHeader">
        <div
          className="progress-bar"
          style={{ width: this.state.scrollPostion + '%' }}
        ></div>
      </div>
    );
  }
}
export default Progress;
