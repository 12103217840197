import React from 'react';
import Logo from '../imagens/logo.png';
import Progress from './Progressbar';

function Header() {
  return (
    <>
      <div className="logo-container">
        <a href="#" target="_blank">
          <img className="logo" src={Logo} alt="Logo Farias Brito" />
        </a>
      </div>
      <Progress />
    </>
  );
}

export default Header;
