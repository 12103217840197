import React from 'react';
import './App.css';
import Header from './Componentes/Header';
import Nav from './Componentes/Nav'
import Preloader from './Componentes/Preloader';
import Times from './Componentes/Times';



function App() {
  
  return (
    <>
    <header>
    <Header/>
    <Nav/>
    </header>
    <Times/>
    {/* <Preloader/> */}
    </>
  );
}

export default App;
